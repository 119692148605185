import Toolbar from "@mui/material/Toolbar";
import Layout from "../layout";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {PostDetail} from "./item";
import {Backdrop, CircularProgress} from "@mui/material";
import {Bookmarks} from "./bookmarks";
import {ForReviewPosts, InappropriatePosts, ScheduledPosts, UserPosts} from "./lists";
import dayjs from "dayjs";

export function PostDetailPage() {
  const [post, setPost] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showFuture, setShowFuture] = useState(false)

  let params = useParams();

  useEffect(() => {
    const getPost = () => {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/${params.id}/`;
      const options = {
        method: 'GET',
        headers: {},
      }
      fetch(url, options).then(res => {
        if (res.status === 401) {
          console.log("Unauthorized")
        } else {
          return res.json()
        }
      }).then(data => {
        setPost({
          id: data.id,
          title: data.title || '',
          submitter: data.submitter,
          text: data.status === 'deleted' ? '[This post has been deleted.]' : data.text,
          up_vote_count: data.up_vote_count,
          down_vote_count: data.down_vote_count,
          level: data.level,
          parent_id: data.parent_id,
          status: data.status === 'public' ? null : data.status,
          datetime_posted: data.datetime_posted,
          votes: data.votes || [],
          topic: data.topic,
          bookmarks: data.bookmarks || [],
          comments_count: data.comments_count || 0,
          parent_path: data.parent_path,
          comments: data.comments,
          is_op: data.is_op,
          tags: data.tags,
        })
        if (dayjs(data.datetime_posted) > dayjs()) {
          setShowFuture(true)
        }
      })
    }

    if (!!params.id && (post === null || params.id !== post.id)) {
      getPost()
    }
  }, [params.id, showFuture])

  useEffect(() => {
    if (post) {
      setLoading(false)
    }
  }, [post])
  const loadingScreen = () => {
    return (
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={true}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
    )
  }
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      {loading ? loadingScreen() : <PostDetail showFuture={showFuture} post={post}/>}
    </Layout>
  )
}


export function PostBookmarksPage() {
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <Bookmarks/>
    </Layout>
  )
}


export function ForReviewPostPage() {
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <ForReviewPosts/>
    </Layout>
  )
}


export function InappropriatePostPage() {
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <InappropriatePosts/>
    </Layout>
  )
}


export function ScheduledPostPage() {
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <ScheduledPosts/>
    </Layout>
  )
}


export function UserPostsList() {
  return (
    <Layout>
      <Toolbar sx={{marginY: 2}}/>
      <UserPosts/>
    </Layout>
  )
}