import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {AuthenticatedUserContext} from "./context";
import {Chip, Popper} from "@mui/material";

const styles = (theme) => ({
  popper: {
    width: "fit-content"
  }
});

const CustomPopper = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start"/>;
};

export class AuthorAutocomplete extends React.Component {
  static contextType = AuthenticatedUserContext

  constructor(props) {
    super(props);
    this.state = {
      authors: [],
      accessToken: null,
    }
  }

  async componentDidMount() {
    if (this.context.firebase_user) {
      const token = await this.context.getBackendToken()
      this.setState({
        accessToken: token
      })
      this.fetchUsers(token)
    }
  }

  fetchUsers(accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/?limit=1000&authors=true`;
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    fetch(url, options)
      .then(res => {
        if (res.status === 401) {
          //logout(navigate("/app/login"))
        }
        return res.json()
      })
      .then(res => {
        this.setState({
          authors: res.users,
        })
      }).catch(err => {
      console.log(err)
    })
  }

  handleTextChange = (value) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/users/?authors=true&search=${value}`;
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.state.accessToken}`,
      },
    }
    fetch(url, options)
      .then(res => {
        if (res.status === 401) {
          //logout(navigate("/app/login"))
        }
        return res.json()
      })
      .then(res => {
        this.setState({
          authors: res.users,
        })
      }).catch(err => {
      console.log(err)
    })
  }

  render() {
    return (
      <Autocomplete
        PopperComponent={CustomPopper}
        sx={this.props.sx}
        size={"small"}
        id="id_author"
        helpertext="Start typing the name of author"
        value={this.props.value}
        options={this.state.authors}
        getOptionLabel={option => option.display_name || ""}
        isOptionEqualToValue={(option, value) => {
          return option.id === value
        }}
        autoHighlight={true}
        autoSelect={true}
        onChange={this.props.onChange}
        renderOption={(props, option) => {
          return <li {...props} key={option.uid}>{option.display_name} {option.badges.map(item =>
            <Chip component={"span"} sx={{marginX: 1}} size={"small"} key={option.id + item.name} label={item.name}
                  variant="outlined"/>)}</li>
        }}
        renderInput={params =>
          <TextField
            {...params}
            label="Start typing author name"
            variant="outlined"
            onChange={
              (event) => {
                if (event.target.value !== "" || event.target.value != null) {
                  this.handleTextChange(event.target.value)
                } else {
                  this.fetchUsers()
                }
              }
            }
          />
        }
      />
    )
  }
}


export class TopicAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      value: null,
      accessToken: null,
    }
  }

  componentDidMount() {
    this.getTopics()
  }

  getTopics = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/topics/`;
    fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Error");
      }
    })
      .then((data) => {
        this.setState({
          topics: data,
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleTextChange = (value) => {
    if (value.length < 3) {
      return false
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/topics/?search=${value}`;
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          topics: res,
        })
      }).catch(err => {
      console.log(err)
    })
  }

  render() {
    return (
      <Autocomplete
        PopperComponent={CustomPopper}
        sx={this.props.sx}
        id="id_topic"
        helpertext="Start typing the name of topic"
        value={this.props.value}
        size={"small"}
        options={this.state.topics}
        getOptionLabel={option => option.name || ""}
        isOptionEqualToValue={(option, value) => {
          return option.id === value
        }}
        autoHighlight={true}
        autoSelect={true}
        onChange={this.props.onChange}
        renderOption={(props, option) => {
          return <li {...props} key={option.id}>{option.name}</li>
        }}
        renderInput={params =>
          <TextField
            {...params}
            label="Start typing topic"
            variant="outlined"
            onChange={
              (event) => {
                if (event.target.value !== "" || event.target.value != null) {
                  this.handleTextChange(event.target.value)
                }
              }
            }
          />
        }
      />
    )
  }
}


export class TagsAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      value: null,
    }
  }

  componentDidMount() {
    this.getTags()
  }

  getTags = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/tags/`;
    fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log("Error");
      }
    })
      .then((data) => {
        this.setState({
          tags: data,
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleTextChange = (value) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/tags/?search=${value}`;
    fetch(url)
      .then(res => res.json())
      .then(res => {
        this.setState({
          topics: res,
        })
      }).catch(err => {
      console.log(err)
    })
  }

  render() {
    return (
      <Autocomplete
        multiple={true}
        PopperComponent={CustomPopper}
        sx={this.props.sx}
        id="id_tags"
        helpertext="Start typing the name of tag"
        value={this.props.value}
        size={"small"}
        options={this.state.tags}
        getOptionLabel={option => option.name || ""}
        isOptionEqualToValue={(option, value) => {
          return option.id === value
        }}
        autoHighlight={true}
        autoSelect={true}
        onChange={this.props.onChange}
        renderOption={(props, option) => {
          return <li {...props} key={option.id}>{option.name}</li>
        }}
        renderInput={params =>
          <TextField
            {...params}
            label="Start typing tag"
            variant="outlined"
            onChange={
              (event) => {
                if (event.target.value !== "" || event.target.value != null) {
                  this.handleTextChange(event.target.value)
                }
              }
            }
          />
        }
      />
    )
  }
}
