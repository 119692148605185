import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {
  Avatar,
  CardActionArea,
  CardActions,
  CardHeader,
  Chip,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  Popover
} from "@mui/material";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardContent from "@mui/material/CardContent";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {useContext, useEffect, useState} from "react";
import withSnackbar from "../snackbar"
import {EditPostForm, PostForm} from "./form";
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import ShareIcon from '@mui/icons-material/Share';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {AuthenticatedUserContext} from "../../context";
import {checkValueinArray} from "../utilities";
import {Reply} from "@mui/icons-material";
import {Bookmark, NeedLoginDialog, postTextStyling, postTitleStyling, updatePostFromProps} from "./utilities";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Comments} from "./comments";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Highlighter from "react-highlight-words";

export function PostCardHeader(props) {
  const {action, submitter, isOP} = props
  const {user} = useContext(AuthenticatedUserContext)
  dayjs.extend(relativeTime);

  useEffect(() => {
  }, [isOP])
  const isAdmin = (
    user &&
    "roles" in user &&
    (
      checkValueinArray(user.roles, "superadmin", "name") ||
      checkValueinArray(user.roles, "admin", "name")
    )
  )

  return (
    <CardHeader
      sx={{paddingY: 0}}
      avatar={<Avatar
        sx={{width: 45, height: 45}}
        src="https://i.pinimg.com/originals/66/25/0e/66250e5096b2e756b1c8184676ab46e9.jpg"/>
      }
      title={
        <Typography sx={{fontSize: 18}}>
          {isAdmin ? (
            <Link component={RouterLink} to={{pathname: `/posts/users/${submitter.id}`}} underline="none">
              {submitter.display_name}
            </Link>
          ) : <Link>{submitter.display_name}</Link>}
          {!!isOP ?
            <Typography component="span" variant="subtitle1" sx={{marginX: 2}} color="primary"> OP</Typography> : null}
        </Typography>
      }
      subheader={
        <Box sx={{display: "flex", alignItems: "start"}}>
          {submitter.domain ?
            <Chip color={"info"} key={submitter.domain} label={submitter.domain.name}
                  sx={{marginX: .5, fontSize: 9}}
                  component="span"
                  size={"small"}/> : null}
          {submitter.badges ? submitter.badges.map(badge => (
            <Chip key={badge.id} sx={{marginX: .5, fontSize: 9}} component="span"
                  label={badge.name.toUpperCase()}
                  size="small"/>
          )) : null}
        </Box>
      }
      action={action}
    />
  )
}


export function PostCardContent(props) {
  const {title, text, topic, parentId, datetime_posted, highLightedTexts, tags} = props
  const datetime = dayjs(datetime_posted).fromNow();

  const getTopicsDisplay = () => {
    const isParentPost = (parentId === null || parentId === 0)
    return (
      isParentPost ?
        <Chip sx={{fontSize: 10}} component="span" label={topic.name.toUpperCase()} variant="outlined"
              size="small"/>
        : null
    )
  }
  return (
    <CardContent sx={{paddingY: 0}}>
      <Box>
        <Typography sx={{fontSize: 10}}>
          {getTopicsDisplay()}
          {datetime_posted &&
            <Typography component="span" sx={{marginX: 2, fontSize: 10}}>{datetime}</Typography>}
        </Typography>
      </Box>
      <Typography>
        <Highlighter
          style={postTitleStyling}
          searchWords={highLightedTexts ? highLightedTexts.split(" ") : []}
          textToHighlight={title}
        />
      </Typography>
      <Highlighter
        style={postTextStyling}
        searchWords={highLightedTexts ? highLightedTexts.split(" ") : []}
        textToHighlight={text}
      />
      <Box>
        {tags ? tags.map(tag => (
          <Chip key={tag.id} sx={{marginX: .5, fontSize: 9}} component="span"
                label={tag.name.toUpperCase()}
                size="small"/>
        )) : null}
      </Box>
    </CardContent>
  )
}


function BasePostCardActions(props) {
  const {
    id,
    votes,
    level,
    topic_id,
    up_vote_count,
    down_vote_count,
    comments_count,
    showComments,
    setShowComments,
    setComments,
    successVoteCallback,
    setNewReply,
    levelLimit,
    actionList,
    parent_datetime_posted,
    showFuture,
    tags,
  } = props
  const [commentsCount, setCommentsCount] = useState(comments_count)
  const [shareIconAnchor, setShareIconAnchorState] = useState(null);
  const [showNeedLoginDialog, setShowNeedLoginDialogState] = useState(false);
  const [showReplyFormState, setShowReplyFormState] = useState(false);
  const {isVerified, firebase_user, user, getBackendToken} = useContext(AuthenticatedUserContext);
  const [actions, setActions] = useState(['upvote', 'downvote', 'comments', 'reply', 'flag', 'share'])
  const navigate = useNavigate()

  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (firebase_user) {
      setupAccessToken()
    }

  }, [firebase_user])

  useEffect(() => {
    if (actionList && actionList.length > 0) {
      setActions(actionList)
    }
  }, [levelLimit, actionList, showFuture])

  useEffect(() => {
    setCommentsCount(comments_count)
  }, [comments_count])

  const vote = (voteType) => {
    const data = {
      headers: {Authorization: `Bearer ${accessToken}`},
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/${id}/vote/${voteType}/`
    fetch(url, data).then((response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 401) {
        alert("Unauthorized. Please login again.");
      } else {
        console.log("Error");
      }
    })
      .then(data => {
        successVoteCallback(data)
      })
      .catch(error => {
        console.log(error);
      });
  }

  const hasUpvoted = () => {
    if (!user) {
      return false
    }
    if (votes.length < 1) {
      return false;
    }
    return votes.some(item => {
      return item.user_id === user.id && item.vote_type === 'upvote';
    })
  }

  const hasDownvoted = () => {
    if (!user) {
      return false
    }
    if (votes.length < 1) {
      return false;
    }
    return votes.some(item => {
      return item.user_id === user.id && item.vote_type === 'downvote';
    })
  }

  const hasVotedInappropriate = () => {
    if (!user) {
      return false
    }
    if (votes.length < 1) {
      return false;
    }
    return votes.some(item => {
      return item.user_id === user.id && item.vote_type === 'inappropriate';
    })
  }

  const upvote = () => {
    vote('upvote')
  }

  const downvote = () => {
    vote('downvote')
  }

  const flagAsInappropriate = () => {
    vote('inappropriate')
  }

  const handleReplySuccessSubmit = (data) => {
    if (data.status === "review") {
      props.snackbarShowMessage(`Your post is for review. It will be visible after it is approved by the admin.`)
      setShowReplyFormState(false)
    } else if (dayjs(data.datetime_posted) > dayjs() && !showFuture) {
      props.snackbarShowMessage(`You created a scheduled reply. It will be visible on ${dayjs(data.datetime_posted).format('LLL')}`)
      setShowReplyFormState(false)
    } else {
      setComments(data)
      setShowReplyFormState(false)
      setNewReply(data)
    }
    window.location.reload(false);
  }

  const replyForm = {
    level: level + 1,
    parent_id: id,
    submitter_id: user ? user.id : null,
    tags_id: tags ? tags.map(a => a.id) : [],
    topic_id: topic_id,
    parent_datetime_posted: parent_datetime_posted,

  }
  const actionOrLoginDialog = (condition, callback) => {
    if (condition) {
      callback()
    } else {
      setShowNeedLoginDialogState(true)
    }
  }

  const commentBox = () => {
    const attributes = {}

    if (isVerified && levelLimit === level) {
      attributes.component = RouterLink
      attributes.to = {pathname: `/posts/${id}`}
    } else if (isVerified) {
      attributes.onClick = () => {
        setShowComments(!showComments)
      }
    } else {
      attributes.onClick = setShowNeedLoginDialogState.bind(true)
    }
    return (<Link disabled sx={{cursor: "pointer"}} {...attributes}>
      {showComments ? 'Hide ' : 'Show '} {commentsCount} {commentsCount > 1 ? 'comments' : 'comment'}
    </Link>)
  }

  const replyBox = () => {
    const attributes = {}

    if (isVerified && levelLimit === level) {
      attributes.onClick = () => navigate(`/posts/${id}`)
    } else if (isVerified) {
      attributes.onClick = () => setShowReplyFormState(!showReplyFormState)
    } else {
      attributes.onClick = setShowNeedLoginDialogState.bind(true)
    }
    return (
      <IconButton
        sx={{fontSize: 17}}
        color={showReplyFormState ? "primary" : "inherit"}
        {...attributes}
        aria-label="reply">
        <Reply sx={{fontSize: 'inherit'}}/>
      </IconButton>
    )
  }

  return (
    <>
      <CardActions sx={{paddingY: 0}}>
        {actions.includes('upvote') ?
          <IconButton
            color={hasUpvoted() === true ? "primary" : "inherit"}
            onClick={() => {
              actionOrLoginDialog(isVerified, upvote)
            }}
            sx={{fontSize: 17}}
            aria-label="upvote">
            <ArrowUpwardIcon sx={{fontSize: 'inherit'}}/>
            <Typography sx={{fontSize: 'inherit'}}>{up_vote_count}</Typography>
          </IconButton> : null
        }
        {actions.includes('downvote') ?
          <IconButton
            color={!!hasDownvoted() ? "error" : "inherit"}
            onClick={() => {
              actionOrLoginDialog(isVerified, downvote)
            }}
            sx={{fontSize: 17}}
            aria-label="downvote">
            <ArrowDownwardIcon sx={{fontSize: 'inherit'}}/>
            <Typography sx={{fontSize: 'inherit'}}>{down_vote_count}</Typography>
          </IconButton> : null
        }
        {actions.includes('reply') ?
          replyBox() : null
        }
        {actions.includes('flag') ?
          <IconButton
            color={!!hasVotedInappropriate() ? "error" : "inherit"}
            sx={{fontSize: 17}}
            onClick={() => actionOrLoginDialog(isVerified, flagAsInappropriate)} aria-label="flag as inappropriate">
            {hasVotedInappropriate() ? <FlagIcon sx={{fontSize: 'inherit'}}/> :
              <FlagOutlinedIcon sx={{fontSize: 'inherit'}}/>}
          </IconButton> : null
        }
        {actions.includes('share') ?
          <IconButton
            sx={{fontSize: 17}}
            onClick={event => setShareIconAnchorState(event.currentTarget)}>
            <ShareIcon sx={{fontSize: 'inherit'}}/>
          </IconButton> : null
        }
        <Box sx={{marginX: 3, fontSize: 14}}>
          {commentsCount > 0 ? commentBox() : null}
        </Box>
      </CardActions>
      {showReplyFormState &&
        <PostForm showFuture={showFuture} successSubmit={handleReplySuccessSubmit} {...replyForm} />}
      <Popover
        id={id}
        open={shareIconAnchor !== null}
        anchorEl={shareIconAnchor}
        onClose={() => setShareIconAnchorState(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{p: 1, m: 1}}>Copy Link</Typography>
        <Typography
          sx={{
            p: 1,
            border: 1,
            m: 1,
            borderColor: "#cecdcd"
          }}>{`${window.location.origin}/posts/${id}`}</Typography>
      </Popover>
      <NeedLoginDialog
        open={showNeedLoginDialog}
        onClose={() => setShowNeedLoginDialogState(false)}/>
    </>
  )
}


export function PostHeaderActions(props) {
  const [isBookMarked, setIsBookMarked] = useState(false)
  const [vertiIconAnchor, setVertiIconAnchorState] = useState(null);
  const {
    id,
    bookmarks,
    submitter_id,
    bookmarkCallback,
    unbookmarkCallback,
    handleEditCallback,
    handleDeleteCallback
  } = props
  const {isVerified, user} = useContext(AuthenticatedUserContext)
  useEffect(() => {
    if (bookmarks.length > 0 && user) {
      setIsBookMarked(checkValueinArray(bookmarks, user.id, "user_id"))
    }
  }, [bookmarks, user])

  useEffect(() => {
  }, [user, isVerified])

  const canEditDelete = () => {
    if (!user) {
      return false
    }
    return (('roles' in user) && (
      checkValueinArray(user.roles, "superadmin", "name") ||
      checkValueinArray(user.roles, "admin", "name") ||
      checkValueinArray(user.roles, "moderator", "name")
    )) || (user.id === submitter_id)
  }

  const bookmarkSuccessCallback = (newBookmark) => {
    bookmarkCallback(newBookmark)
    setIsBookMarked(true)
  }

  const unbookmarkSuccessCallback = () => {
    unbookmarkCallback()
    setIsBookMarked(false)
  }

  const openVertiIcon = () => {
    return Boolean(vertiIconAnchor);
  }

  const handleVertiIconClick = (event) => {
    setVertiIconAnchorState(event.currentTarget)
  };

  const handleVertiIconClose = () => {
    setVertiIconAnchorState(null)
  };

  return (
    <>
      {isVerified ?
        <>
          <Bookmark bookmarked={isBookMarked} postId={id} unbookmarkSuccessCallback={unbookmarkSuccessCallback}
                    successCallback={bookmarkSuccessCallback}/>
          {canEditDelete() ? <IconButton
            aria-controls={openVertiIcon() ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openVertiIcon() ? 'true' : undefined}
            onClick={handleVertiIconClick}
            aria-label="settings"
          >
            <MoreVertIcon/>
          </IconButton> : null}
        </>
        :
        null
      }
      {canEditDelete() ?
        <>
          <Menu
            id="basic-menu"
            anchorEl={vertiIconAnchor}
            open={openVertiIcon()}
            onClose={handleVertiIconClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => {
              handleEditCallback()
              handleVertiIconClose()
            }}>Edit</MenuItem>
            <MenuItem onClick={() => {
              handleDeleteCallback()
              handleVertiIconClose()
            }}>Delete</MenuItem>
          </Menu>
        </>
        : null
      }
    </>
  )
}


export function PostDetails(props) {
  const {
    post,
    setPost,
    comments,
    setComments,
    setShowDetails,
    showComments,
    setShowComments,
    levelLimit,
    showFuture,
  } = props

  const [newReply, setNewReply] = useState([])
  const {firebase_user} = useContext(AuthenticatedUserContext)

  useEffect(() => {
  }, [showFuture, post])

  const deleteCallback = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/posts/${post.id}/`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${firebase_user.accessToken}`,
      }
    }
    fetch(url, options).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            setPost(data)
          })
        } else {
          throw new Error('Failed to delete post.');
        }
      }
    ).catch(err => alert(err))
  }

  return (
    <>
      <PostCardHeader
        submitter={post.submitter}
        datetime_posted={post.datetime_posted}
        isOP={post.is_op}
        action={
          <PostHeaderActions
            id={post.id}
            bookmarks={post.bookmarks}
            parentId={post.parent_id}
            submitter_id={post.submitter.id}
            bookmarkCallback={(newBookmark) => {
              setPost({
                ...post,
                bookmarks: [...post.bookmarks, newBookmark]
              })
            }}
            unbookmarkCallback={() => {
              setPost({
                ...post,
                bookmarks: post.bookmarks.filter(bookmark => bookmark.id !== post.id)
              })
            }}
            handleEditCallback={() => {
              setShowDetails(false)
            }}
            handleDeleteCallback={deleteCallback}
          />}
      />
      <PostCardContent
        title={post.title}
        text={post.text}
        topic={post.topic}
        parentId={post.parent_id}
        datetime_posted={post.datetime_posted}
        tags={post.tags}
      />
      <PostCardActions
        id={post.id}
        votes={post.votes}
        level={post.level}
        up_vote_count={post.up_vote_count}
        down_vote_count={post.down_vote_count}
        parent_datetime_posted={post.datetime_posted}
        comments_count={post.comments_count}
        showComments={showComments}
        setShowComments={showComment => setShowComments(showComment)}
        showFuture={showFuture}
        tags={post.tags}
        successVoteCallback={data =>
          setPost({
            ...post,
            id: data.id,
            submitter: data.submitter,
            text: data.text,
            up_vote_count: data.up_vote_count,
            down_vote_count: data.down_vote_count,
            level: data.level,
            parent_id: data.parent_id,
            status: data.status === 'public' ? null : data.status,
            datetime_posted: data.datetime_posted,
            votes: data.votes || [],
            topic: data.topic,
            topic_id: data.topic.id,
          })
        }
        levelLimit={levelLimit}
        setComments={newComment =>
          setComments([newComment, ...comments])
        }
        setNewReply={(data) =>
          setNewReply([data, ...newReply])
        }
      />
    </>
  )
}


export function ReturnToParentPost(props) {
  const {parentPath} = props
  const navigate = useNavigate()
  return (
    <Box sx={{paddingX: 2, marginY: 1}}>
      <Paper color="gray" elevation={0}>
        <Button onClick={() => navigate(parentPath)} startIcon={<KeyboardBackspaceIcon/>} variant="outlined">
          Back to post
        </Button>
      </Paper>
    </Box>
  )
}


export function ListCardHeader(props) {
  const {submitter, datetime_posted, action} = props
  const {user} = useContext(AuthenticatedUserContext)

  dayjs.extend(relativeTime);
  const datetime = dayjs(datetime_posted).fromNow();

  const isAdmin = (
    user &&
    "roles" in user &&
    (
      checkValueinArray(user.roles, "superadmin", "name") ||
      checkValueinArray(user.roles, "admin", "name")
    )
  )

  return (
    <CardHeader
      sx={{paddingY: 0}}
      title={
        <Typography sx={{fontSize: 15}}>
          {isAdmin ? (
            <Link component={RouterLink} to={{pathname: `/posts/users/${submitter.id}`}} underline="none">
              {submitter.display_name}
            </Link>
          ) : <Link underline="none">{submitter.display_name}</Link>}
          {submitter.badges ? submitter.badges.map(badge => (
            <Chip key={badge.id} sx={{marginY: 1, marginX: 1, fontSize: 9, paddingY: 0}} component="span"
                  label={badge.name.toUpperCase()}
                  size="small"/>
          )) : null
          }
        </Typography>
      }
      subheader={
        <Typography sx={{fontSize: 10}}>
          {props.topic}
          <Typography component="span" sx={{marginX: 2, marginY: "auto", fontSize: 9}}>{datetime}</Typography>
        </Typography>
      }
      action={action}
    />
  )
}


export function ListCardHeaderAction(props) {
  const {post, setPost} = props
  const [isBookMarked, setIsBookMarked] = useState(false)
  const {user} = useContext(AuthenticatedUserContext)

  useEffect(() => {
    if (post.bookmarks.length > 0 && user) {
      setIsBookMarked(checkValueinArray(post.bookmarks, user.id, "user_id"))
    }
  }, [user, post.bookmarks])

  const bookmarkSuccessCallback = (newBookmark) => {
    setPost({
      ...post,
      bookmarks: [...post.bookmarks, newBookmark]
    })
    setIsBookMarked(true)
  }

  const unbookmarkSuccessCallback = () => {
    setPost({
      ...post,
      bookmarks: post.bookmarks.filter(bookmark => bookmark.post_id !== post.id)
    })
    setIsBookMarked(false)
  }
  return (
    <Bookmark bookmarked={isBookMarked} postId={post.id} unbookmarkSuccessCallback={unbookmarkSuccessCallback}
              successCallback={bookmarkSuccessCallback}/>
  )
}


export function BasePost(props) {
  const [post, setPost] = useState(updatePostFromProps(props.post))
  const [comments, setComments] = useState(props.post.comments || [])
  const [showDetail, setShowDetail] = useState(true)
  const [showComments, setShowComments] = useState(true)
  const [levelLimit, setLevelLimit] = useState(null)
  const [showFuture, setShowFuture] = useState(props.showFuture)

  const handleEditSuccessSubmit = data => {
    const newPost = data.item
    if (newPost.status === "review") {
      props.snackbarShowMessage(`Your post is for review. It will be visible after it is approved by the admin.`)
    }
    setPost({
      ...post,
      id: newPost.id,
      title: newPost.title,
      submitter: newPost.submitter,
      text: newPost.text,
      up_vote_count: newPost.up_vote_count,
      down_vote_count: newPost.down_vote_count,
      level: newPost.level,
      parent_id: newPost.parent_id,
      status: newPost.status === 'public' ? null : newPost.status,
      datetime_posted: newPost.datetime_posted,
      topic: newPost.topic,
      topic_id: newPost.topic.id,
      is_op: newPost.is_op,
      tags: newPost.tags,
    })
    setShowDetail(true)
    if (!!data.message) {
      props.snackbarShowMessage(data.message)
    }
  }

  useEffect(() => {
    setPost(updatePostFromProps(props.post))
    setShowDetail(true)
    setShowComments(true)
  }, [props.post])

  useEffect(() => {
  }, [comments])
  return (
    <Box sx={{marginTop: 1, marginBottom: 0}}>
      {!!post.parent_id && post.parent_path ?
        <ReturnToParentPost parentPath={post.parent_path}/> : null}
      <Card elevation={0}
            sx={{
              padding: 0,
              borderColor: 'grey.500',
              marginBottom: 0,
              paddingBottom: 0
            }}>
        {(showDetail) ? (
          <PostDetails
            post={post}
            setPost={value => setPost(value)}
            comments={comments}
            setComments={value => setComments(value)}
            setShowDetails={value => setShowDetail(value)}
            showComments={showComments}
            setShowComments={value => setShowComments(value)}
            levelLimit={levelLimit}
            showFuture={showFuture}
          />
        ) : (
          <EditPostForm
            closeForm={() => setShowDetail(true)}
            successSubmit={handleEditSuccessSubmit}
            {...post}
          />
        )}
        <Box>
          {showComments && comments.length > 0 ?
            <Comments comments={comments}
                      showFuture={showFuture}
                      parentDatetimePosted={post.datetime_posted}
                      levelLimit={levelLimit}
            /> : null}
        </Box>
      </Card>
    </Box>
  )
}


export function PostList(props) {
  const {cardHeader, cardContent, cardActions, cardActionList} = props
  const [post, setPost] = useState({
    id: props.post.id,
    title: props.post.title || '',
    submitter: props.post.submitter,
    text: props.post.text,
    up_vote_count: props.post.up_vote_count,
    down_vote_count: props.post.down_vote_count,
    level: props.post.level,
    parent_id: props.post.parent_id,
    status: props.post.status === 'public' ? null : props.post.status,
    datetime_posted: props.post.datetime_posted,
    votes: props.post.votes || [],
    topic: props.post.topic,
    bookmarks: props.post.bookmarks || [],
    comments_count: props.post.comments_count || 0,
    tags: props.post.tags || [],
  })
  const [shareIconAnchor, setShareIconAnchor] = useState(null)
  const navigate = useNavigate()

  const topic = () => (
    <Typography sx={{fontSize: 'inherit'}} component="span">{post.topic.name.toUpperCase()}</Typography>
  )
  return (
    <Card elevation={0} sx={{marginTop: 2}}>
      {cardHeader ? cardHeader :
        <ListCardHeader
          id={post.id}
          submitter={post.submitter}
          datetime_posted={post.datetime_posted}
          topic={!!post.topic ? topic() : null}
          action={<ListCardHeaderAction post={post} setPost={setPost}/>}
        />
      }
      {cardContent ? cardContent :
        <CardActionArea onClick={() => navigate(`/posts/${post.id}`)}>
          <CardContent sx={{paddingY: 0}}>
            <Typography variant="h6">{post.title}</Typography>
            <Box>
              {post.tags ? post.tags.map(tag => (
                <Chip key={tag.id} sx={{marginX: .5, fontSize: 9}} component="span"
                      label={tag.name.toUpperCase()}
                      size="small"/>
              )) : null}
            </Box>
          </CardContent>
        </CardActionArea>
      }
      {cardActions ? cardActions :
        <PostCardActions
          id={post.id}
          votes={post.votes}
          level={post.level}
          up_vote_count={post.up_vote_count}
          down_vote_count={post.down_vote_count}
          comments_count={post.comments_count}
          showComments={false}
          tags={post.tags}
          setShowComments={() => navigate(`/posts/${post.id}`)}
          successVoteCallback={data =>
            setPost({
              ...post,
              id: data.id,
              submitter: data.submitter,
              text: data.text,
              up_vote_count: data.up_vote_count,
              down_vote_count: data.down_vote_count,
              level: data.level,
              parent_id: data.parent_id,
              status: data.status === 'public' ? null : data.status,
              datetime_posted: data.datetime_posted,
              votes: data.votes || [],
              topic: data.topic,
              topic_id: data.topic.id,
            })
          }
          actionList={cardActionList || ['upvote', 'downvote', 'comments', 'flag', 'share']}
          parent_datetime_posted={post.datetime_posted}
        />
      }
      <Popover
        id={post.id}
        open={shareIconAnchor !== null}
        anchorEl={shareIconAnchor}
        onClose={() => setShareIconAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{p: 1, m: 1}}>Copy Link</Typography>
        <Typography
          sx={{
            p: 1,
            border: 1,
            m: 1,
            borderColor: "#cecdcd"
          }}>{`${window.location.origin}/app/posts/${post.id}`}</Typography>
      </Popover>
    </Card>
  )
}


export function PostDetail(props) {
  const {showFuture, post} = props

  useEffect(() => {
  }, [showFuture], post)

  return (
    <Card elevation={0}>
      <Post showFuture={showFuture} post={post}/>
    </Card>
  )
}


export function ReadOnlyPost(props) {
  const {post, actions, title, text} = props

  return (
    <Card elevation={0}>
      <PostCardHeader
        submitter={post.submitter}
        datetime_created={post.datetime_created}
      />
      <PostCardContent
        title={title}
        text={text}
        topic={post.topic}
        parentId={post.parent_id}
        datetime_created={post.datetime_created}
      />
      {actions}
    </Card>
  )
}


export const Post = withSnackbar(BasePost);
export const PostCardActions = withSnackbar(BasePostCardActions);
