import {DataGrid, GridActionsCellItem, GridRowParams} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthenticatedUserContext} from "../../context";
import dayjs from "dayjs";
import CheckIcon from '@mui/icons-material/Check';

export function NotifyDomainTable() {
  const [emails, setEmails] = useState([])
  const {isVerified, firebase_user, getBackendToken} = useContext(AuthenticatedUserContext)
  const pulledData = useRef(false)
  const [accessToken, setAccessToken] = useState(null)

  const getEmails = () => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/core/notify-domain/`;
    const options = {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      }
    }
    fetch(url, options).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Something went wrong')
      }
    }).then(data => {
      return setEmails(data)
    }).catch(error => {
      console.log(error)
    })
  }

  const isNotifiedDisplay = (params) => {
    if (params.value === null || params.value === undefined) {
      return ""
    }
    return params.value ? 'Notified' : 'Waiting'
  }

  useEffect(() => {
    if (emails.length === 0 && isVerified && accessToken && !pulledData.current) {
      getEmails()
      pulledData.current = true
    }

    async function setupAccessToken() {
      setAccessToken(await getBackendToken())
    }

    if (firebase_user && !accessToken) {
      setupAccessToken()
    }

  }, [isVerified, firebase_user, getEmails, accessToken])

  const notifyEmail = (params) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/core/notify-domain/${params.row.id}/`;
    const options = {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
      body: JSON.stringify({is_notified: true, email: params.row.email})
    }
    fetch(url, options).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Something went wrong')
      }
    }).then(data => {
      setEmails(emails.map((row) => (row.id === data.id ? data : row)));
    })
  }

  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
    },
    {
      field: "is_notified",
      headerName: "Status",
      flex: 1,
      renderCell: isNotifiedDisplay,
    },
    {
      field: "datetime_notified",
      headerName: "Notified Date and Time",
      flex: 1,
      renderCell: (params) => (
        <p>{params.value ? dayjs(params.value).format("LLL") : ""}</p>
      )
    },
    {
      field: "actions",
      headerName: "Update Status",
      type: 'actions',
      flex: 1,
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem disabled={params.row.is_notified === true}
                               icon={<CheckIcon color={params.row.is_notified === true ? 'inherit' : 'success'}/>}
                               onClick={() => notifyEmail(params)}
                               label="Notified"/>
        ]
      }
    },
  ]

  return (
    <Box>
      {pulledData.current ?
        <DataGrid
          autoHeight
          getRowId={(row) => row.id}
          rowHeight={80}
          experimentalFeatures={{newEditingApi: true}}
          rows={emails}
          columns={columns}
        />
        :
        <div>Loading...</div>
      }
    </Box>
  )
}
