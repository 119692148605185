import React from "react"

import {BottomNavBar, DefaultNavBar} from "./navBar";
import {Auth0Provider} from "@auth0/auth0-react";
import {AnonymedProvider} from "../context";

const Layout = ({children}) => (
  <BaseLayout>
    <DefaultNavBar/>
    {children}
    <BottomNavBar/>
  </BaseLayout>
)

export const BaseLayout = ({children}) => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL
    }}
  >
    <AnonymedProvider>
      {children}
    </AnonymedProvider>
  </Auth0Provider>
)
export default Layout
