import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {DoximityCallback} from "./components/doximity";
import {AuthFlow} from "./components/auths/login";
import {
  ForReviewPostPage,
  InappropriatePostPage,
  PostBookmarksPage,
  PostDetailPage,
  ScheduledPostPage,
  UserPostsList
} from "./components/posts";
import {UsersPage} from "./components/users";
import {NotifyEmailsTablePage} from "./components/domains";
import {DashboardPage} from "./components/dashboard";
import {AuditPage} from "./components/audit";
import {FeedbackPage} from "./components/feedbacks";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DevSupport
      ComponentPreviews={ComponentPreviews}
      useInitialHook={useInitial}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App/>}/>
          <Route path="posts/:id" element={<PostDetailPage/>}/>
          <Route path="posts/bookmarks" element={<PostBookmarksPage/>}/>
          <Route path="posts/for-reviews" element={<ForReviewPostPage/>}/>
          <Route path="posts/inappropriates" element={<InappropriatePostPage/>}/>
          <Route path="posts/scheduled" element={<ScheduledPostPage/>}/>
          <Route path="posts/users/:user_id" element={<UserPostsList/>}/>
          <Route path="users/" element={<UsersPage/>}/>
          <Route path="login/" element={<AuthFlow/>}/>
          <Route path="oauth/callback/doximity/" element={<DoximityCallback/>}/>
          <Route path="admin/notify-emails/" element={<NotifyEmailsTablePage/>}/>
          <Route path="admin/dashboard/" element={<DashboardPage/>}/>
          <Route path={"admin/audits/"} element={<AuditPage/>}/>
          <Route path={"admin/feedbacks/"} element={<FeedbackPage/>}/>
          <Route path={".well-known/apple-app-site-association/"}/>
        </Routes>
      </BrowserRouter>
    </DevSupport>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
