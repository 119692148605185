import React, {createContext, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";

const contextDefault = {
  isLoggedIn: false,
  getBackendToken: null,
  firebase_user: null,
  user: null,
  isVerified: false
}
export const AuthenticatedUserContext = createContext(contextDefault);


export const AnonymedProvider = ({children}) => {
  const {user, getAccessTokenSilently} = useAuth0();

  const getBackendToken = async () => {
    return await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_BACKEND_AUDIENCE,
      }
    })
  }

  const [context, setContext] = useState({
    ...contextDefault,
    getBackendToken: getBackendToken,
  })

  useEffect(() => {
    const isLoggedInNow = user && user.email_verified
    if (isLoggedInNow !== undefined) {
      setContext({
        ...context,
        isLoggedIn: isLoggedInNow,
        firebase_user: user,
      })
    }
  }, [user])

  useEffect(() => {
    const getUser = (accessToken) => {
      const api_url = `${process.env.REACT_APP_API_URL}/api/v1/users/me/`
      fetch(api_url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then(res => {
          if (res.status === 200) {
            return res.json()
          } else {
            return Promise.reject(res.statusText)
          }
        })
        .then(data => {
          setContext({
            ...context,
            user: data,
            isVerified: data.auth_step === 'completed',
          })
        })
        .catch(err => {
          alert(err)
        })
    }

    async function setupUser() {
      if (!context.isLoggedIn) {
        return false
      }
      if (user.email_verified) {
        const accessToken = await getBackendToken()
        getUser(accessToken)
      }
    }

    setupUser()
  }, [context.isLoggedIn])

  useEffect(() => {
    console.log(context)
  }, [context.firebase_user])
  return (
    <AuthenticatedUserContext.Provider value={context}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
}
