import {Dialog, DialogContent, FormControl, Grid, IconButton, TextField} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import React, {useContext, useEffect, useState} from "react";
import {AuthenticatedUserContext} from "../../context";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

const DEFAULT_LOGIN_MESSAGE = "Log in or Sign up to see more"

export const postTitleStyling = {
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontWeight: 530,
  fontSize: '1.25rem',
  lineHeight: 1.6,
  letterSpacing: '0.0075em',
}

export const postTextStyling = {
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: 1.5,
  letterSpacing: '0.00938em',
}


export function Bookmark(props) {
  const {isVerified, getBackendToken, user} = useContext(AuthenticatedUserContext)
  const [accessToken, setAccessToken] = useState(null)
  const {postId, bookmarked} = props

  useEffect(() => {
    async function setupAccessToken() {
      if (!accessToken) {
        setAccessToken(await getBackendToken())
      }
    }

    setupAccessToken()
  }, [isVerified, props.bookmarked, props.postId])

  const bookmarkPost = async () => {
    if (!isVerified) {
      return null
    }
    const data = {
      user_id: user.id,
      post_id: postId
    }
    const url = `${process.env.REACT_APP_API_URL}/api/v1/bookmarks/`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
    fetch(url, options).then(async res => {
      if (res.status === 201) {
        return res.json().then(data => {
          return props.successCallback(data)
        })

      } else if (res.status === 200) {
        props.unbookmarkSuccessCallback()
      } else {
        throw new Error('Failed to bookmark post');
      }
    }).catch(err => alert(err))
  }

  return (
    <>
      {isVerified ? <IconButton
        aria-label="edit"
        onClick={bookmarkPost}
      >
        {bookmarked ? <BookmarkIcon/> : <BookmarkBorderOutlinedIcon/>}
      </IconButton> : null}
    </>
  )
}


export function NeedLoginDialog(props) {
  const {onClose, open} = props;
  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent sx={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
        <Alert severity="info">
          <Typography>
            {process.env.REACT_APP_LOGIN_MESSAGE || DEFAULT_LOGIN_MESSAGE}
          </Typography>
        </Alert>
        <Button sx={{marginY: 1}} onClick={handleClose} variant="outlined">Ok</Button>
      </DialogContent>
    </Dialog>
  );
}


export function PostSearch(props) {
  const {handleSearchKeyChange, searchKey} = props

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item lg={6} md={10} xs={12} sm={11}>
        <FormControl fullWidth>
          <TextField
            value={searchKey}
            id="outlined-start-adornment"
            placeholder="Search post..."
            variant="standard"
            onChange={event => handleSearchKeyChange(event.target.value)}
            InputProps={{
              startAdornment: <SearchIcon position="start"/>,
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}


export function updatePostFromProps(post) {
  return {
    id: post.id,
    submitter: post.submitter,
    text: post.text,
    title: post.title,
    up_vote_count: post.up_vote_count,
    down_vote_count: post.down_vote_count,
    level: post.level,
    parent_id: post.parent_id,
    status: post.status === 'public' ? null : post.status,
    datetime_posted: post.datetime_posted,
    votes: post.votes || [],
    topic: post.topic,
    bookmarks: post.bookmarks || [],
    comments_count: post.comments_count || 0,
    parent_path: post.parent_path,
    parent_datetime_posted: post.parent_datetime_posted || null,
    tags: post.tags || [],
  }
}